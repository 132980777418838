import React from "react";
import styles from "./App.module.scss";
import Grid from "./Grid";

const HIDE_TIMEOUT = 500; /* ms */

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			minScore: 0,
			mosse: 0,
			grid: this.generateGrid(),
			currentIndex: null,
			isHiding: false,
		};
	}

	generateGrid = () => {
		let syms = ["😀", "😁", "😂", "😄", "😉", "😎", "🤩", "🤓"];
		let grid = [];
		for (let index = 0; index < 8; index++) {
			const item = { symbol: syms[index], revealed: false };
			grid.push({ ...item });
			grid.push({ ...item });
		}
		return grid
			.map((value) => ({ value, sort: Math.random() }))
			.sort((a, b) => a.sort - b.sort)
			.map(({ value }) => value);
	};

	checkWin = () => {
		for (let index = 0; index < this.state.grid.length; index++) {
			const item = this.state.grid[index];
			if (!item.revealed) {
				return false;
			}
		}
		return true;
	};

	reset = () => {
		let minScore = this.state.minScore;
		let mosse = this.state.mosse;
		if (this.checkWin() && (minScore === 0 || mosse < minScore)) {
			minScore = mosse;
		}
		this.setState({
			mosse: 0,
			grid: this.generateGrid(),
			minScore: minScore,
		});
	};

	incrementMoves = () => {
		this.setState({ mosse: this.state.mosse + 1 });
	};

	reveal = (index) => {
		if (this.state.isHiding) {
			return;
		}
		let newGrid = [...this.state.grid];
		let currentIndex = this.state.currentIndex;
		let forHiding = null;
		if (this.state.currentIndex !== null) {
			let current = this.state.grid[currentIndex];
			if (this.state.grid[index].symbol === current.symbol) {
				newGrid[index].revealed = true;
			} else {
				newGrid[index].revealed = true;
				newGrid[currentIndex].revealed = true;
				forHiding = currentIndex;
				this.setState({ isHiding: true });
			}
			currentIndex = null;
		} else {
			newGrid[index].revealed = true;
			currentIndex = index;
		}
		this.setState({ grid: newGrid, currentIndex: currentIndex });
		if (forHiding !== null) {
			setTimeout(() => {
				let newGrid = [...this.state.grid];
				newGrid[forHiding].revealed = false;
				newGrid[index].revealed = false;
				this.setState({
					grid: newGrid,
					currentIndex: currentIndex,
					isHiding: false,
				});
			}, HIDE_TIMEOUT);
		}
	};

	isRevealed = (index) => {
		return this.state.grid[index].revealed;
	};

	render = () => {
		return (
			<div className={styles.base}>
				<h1 className={styles.mainTitle}>Memory</h1>
				<p className={styles.movesNumber}>
					Mosse totali: {this.state.mosse}
					<br />
					Punteggio minimo: {this.state.minScore}
				</p>
				<button
					type="button"
					onClick={this.reset}
					className={styles.resetButton}
				>
					Reset
				</button>
				<Grid
					grid={this.state.grid}
					reveal={this.reveal}
					isRevealed={this.isRevealed}
					incrementMoves={this.incrementMoves}
				/>
			</div>
		);
	};
}
