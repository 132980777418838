import React from "react";
import styles from "./Tile.module.scss";

export default class Tile extends React.Component {
	handleClick = () => {
		if (this.props.isRevealed()) {
			return;
		}
		this.props.reveal();
		this.props.incrementMoves();
	};

	render() {
		return (
			<div className={styles.tile} onClick={this.handleClick}>
				{this.props.isRevealed() && this.props.symbol}
			</div>
		);
	}
}
