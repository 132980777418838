import React from "react";
import styles from "./Grid.module.scss";
import Tile from "./Tile";

export default class Grid extends React.Component {
	render = () => {
		return (
			<div className={styles.grid}>
				{this.props.grid.map((tile, index) => (
					<Tile
						key={index}
						symbol={tile.symbol}
						reveal={() => this.props.reveal(index)}
						isRevealed={() => this.props.isRevealed(index)}
						incrementMoves={this.props.incrementMoves}
					/>
				))}
			</div>
		);
	};
}
